import { GET } from "./config";

export interface NanduConnection {
  nanduId1: number;
  nanduId2: number;
  combo: string;
  grade: string;
  score: number;
}

export interface NanduMovement {
  name: string;
  id: number;
  code: string;
  chineseName?: string;
  displayName: string;
}

export interface NanduFormNandu {
  id: number;
  description: string;
  name: string;
  code: string;
  grade: string;
  score: number;
  nanduType: string;
  connections: string;
  displayName: string;
}

export type ConnectionsMap = Record<string, NanduConnection>;

export interface NanduFormDataNandu
  extends Omit<NanduFormNandu, "connections"> {
  connections: number[];
}

export interface NanduFormData {
  connections: ConnectionsMap;
  movements: NanduMovement[];
  nandu: NanduFormDataNandu[];
  wushuStyle: string;
  isPastDeadline: boolean;
  nanduFormDeadline: Date;
}

const NanduFormAPI = {
  async getNanduFormData(competitionEventId: number): Promise<NanduFormData> {
    const { nandus } = await GET(
      `nanduFormData?competitionEventId=${competitionEventId}`
    );

    return nandus;
  },
};

export default NanduFormAPI;
