import { GET, POST } from 'api/config';

const CheckoutAPI = {
  async getCheckoutInfo() {
    const { checkout } = await GET('checkout');

    return checkout;
  },
  async getCheckoutInfoForUser(id) {
    const { checkout } = await GET(`checkout/${id}`);

    return checkout;
  },
  makePayment(checkoutInput) {
    return POST('checkout', { checkoutInput });
  },
};

export { CheckoutAPI };
