import { SET_IS_OPEN } from './actionTypes';

const initialState = {
  isOpen: false,
  currentItem: null,
};

function reducer(state = initialState, action) {
  switch (action.type) {
    case SET_IS_OPEN:
      return {
        isOpen: action.isOpen,
        currentItem: action.currentItem,
      };
    default:
      return state;
  }
};

export { reducer };