import { SET_COLLAPSED } from './actionTypes';

const initialState = {
  isCollapsed: false,
};

function SideNavReducer(state = initialState, action) {
  switch (action.type) {
    case SET_COLLAPSED:
      return {
        isCollapsed: action.isCollapsed
      };
    default:
      return state;
  }
};

export { SideNavReducer };