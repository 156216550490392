import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "./reducers";

export interface ParticipantTabsState {
  flashingTabs: {
    [participantId: string]: {
      [tabName: string]: boolean;
    };
  };
}

const initialState = {
  flashingTabs: {},
} as ParticipantTabsState;

export const slice = createSlice({
  name: "participantTabs",
  initialState,
  reducers: {
    addTabForParticipant: (
      state,
      action: PayloadAction<{ participantId: number; tabName: string }>
    ) => {
      if (!state.flashingTabs[action.payload.participantId]) {
        state.flashingTabs[action.payload.participantId] = {};
      }

      state.flashingTabs[action.payload.participantId][action.payload.tabName] =
        true;
    },
    unflashTabForParticipant: (
      state,
      action: PayloadAction<{ participantId: number; tabName: string }>
    ) => {
      if (
        state.flashingTabs?.[action.payload.participantId]?.[
          action.payload.tabName
        ]
      ) {
        state.flashingTabs[action.payload.participantId][
          action.payload.tabName
        ] = false;
      }
    },
    deleteTabForParticipant: (
      state,
      action: PayloadAction<{ participantId: number; tabName: string }>
    ) => {
      if (
        state.flashingTabs?.[action.payload.participantId]?.[
          action.payload.tabName
        ] !== undefined
      ) {
        delete state.flashingTabs[action.payload.participantId][
          action.payload.tabName
        ];
      }
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addTabForParticipant,
  deleteTabForParticipant,
  unflashTabForParticipant,
} = slice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getFlashingTabs = (state: RootState) =>
  state.participantTabs.flashingTabs;

export default slice.reducer;
