import { SET_RING_ID } from "./actionTypes";
import { RingSelect, RingSelectAction } from "./types";

function RingSelectReducer(
  state: RingSelect = {},
  action: RingSelectAction
): RingSelect {
  const { type } = action;

  switch (type) {
    case SET_RING_ID: {
      const { selectedDay, ringSelectElementId, ring } = action;

      return {
        ...state,
        [ringSelectElementId]: {
          ...state[ringSelectElementId],
          [selectedDay]: ring,
        },
      };
    }
    default:
      return state;
  }
}

export { RingSelectReducer };
