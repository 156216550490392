import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { Dispatch, PayloadAction } from "@reduxjs/toolkit";

import { EventParticipationAPI } from "api";
import NanduFormAPI from "api/nanduForm";
import type {
  ConnectionsMap,
  NanduConnection,
  NanduFormData,
  NanduFormDataNandu,
  NanduMovement,
} from "api/nanduForm";
import { NANDU } from "helpers/constants";
import {
  calculateNanduScore,
  hasAllRequiredMovements,
  hasDuplicates,
} from "helpers/nanduForm/calculateScore";
import generatePDF from "helpers/nanduForm/generatePDF";
import type { RootState } from "./reducers";

export interface PartialNanduFormDataNandu extends Partial<NanduFormDataNandu> {
  formValue?: number;
  isSelectedAsConnection?: boolean;
  hasMaxInstances?: boolean;
}
export type Section = {
  id: number;
  nandu: PartialNanduFormDataNandu[];
  movement: Partial<NanduMovement>[];
};
export type SelectedNandu = {
  sectionId: number | null;
  nanduIndex: number | null;
};
export type NanduSelection = {
  sectionId: number;
  nanduIndex: number;
};
export interface UserInfo {
  firstName: string;
  lastName: string;
  association: string;
  gender: string;
  height: string;
  email: string;
  phone: string;
}
export interface NanduScores {
  totalScore: number | string;
  totalScoreIsMaxed: boolean;
  jumpScore: number;
  jumpScoreIsMaxed: boolean;
  connectionScore: number;
  connectionScoreIsMaxed: boolean;
  isValidNanduForm: boolean;
  errorMessage?: string;
  innovationScore?: number;
}
export interface MovementScores {
  isValidMovementForm: boolean;
  allRequiredMovements: boolean;
}
export interface NanduFormState {
  sections: Section[];
  selectedNandu: SelectedNandu;
  nanduScores: NanduScores;
  movementScores: MovementScores;
  nanduPDF: {
    filename: string;
    pdf: {};
    userInfo: UserInfo;
  };
  nanduFormType: string;
  wushuStyle: string;
  connections: ConnectionsMap | null;
  movements: NanduMovement[] | null;
  nandu: NanduFormDataNandu[] | null;
  eventParticipationId: number | null;
  competitionEventId: number | null;
  isPastDeadline: boolean | null;
  nanduFormDeadline: Date | null;
}
interface ThunkAPI {
  dispatch: Dispatch;
  state: RootState;
}

const initialState = {
  sections: [
    {
      id: 1,
      nandu: [],
      movement: [],
    },
    {
      id: 2,
      nandu: [],
      movement: [],
    },
    {
      id: 3,
      nandu: [],
      movement: [],
    },
    {
      id: 4,
      nandu: [],
      movement: [],
    },
  ],
  selectedNandu: {
    sectionId: null,
    nanduIndex: null,
  },
  nanduScores: {
    totalScore: 0,
    totalScoreIsMaxed: false,
    jumpScore: 0,
    jumpScoreIsMaxed: false,
    connectionScore: 0,
    connectionScoreIsMaxed: false,
    isValidNanduForm: false,
  },
  movementScores: {
    isValidMovementForm: false,
    allRequiredMovements: false,
  },
  nanduPDF: {
    filename: "",
    pdf: {},
    userInfo: {
      firstName: "",
      lastName: "",
      association: "",
      gender: "",
      height: "",
      email: "",
      phone: "",
    },
  },
  nanduFormType: NANDU,
  wushuStyle: "",
  movements: null,
  nandu: null,
  connections: null,
  eventParticipationId: null,
  competitionEventId: null,
  isPastDeadline: null,
  nanduFormDeadline: null,
} as NanduFormState;

export const slice = createSlice({
  name: "nanduForm",
  initialState,
  reducers: {
    setNanduFormData: (state, action: PayloadAction<NanduFormData>) => {
      // Redux Toolkit allows us to write "mutating" logic in reducers. It
      // doesn't actually mutate the state because it uses the Immer library,
      // which detects changes to a "draft state" and produces a brand new
      // immutable state based off those changes
      state.movements = action.payload.movements;
      state.nandu = action.payload.nandu;
      state.connections = action.payload.connections;
      state.wushuStyle = action.payload.wushuStyle;
      state.isPastDeadline = action.payload.isPastDeadline;
      state.nanduFormDeadline = action.payload.nanduFormDeadline;
    },
    setEventParticipationId: (state, action: PayloadAction<number>) => {
      state.eventParticipationId = action.payload;
    },
    setCompetitionEventId: (state, action: PayloadAction<number | null>) => {
      state.competitionEventId = action.payload;
    },
    setSections: (
      state,
      action: PayloadAction<{ sections: Section[] } | undefined>
    ) => {
      if (action.payload) {
        state.sections = action.payload.sections;
      } else {
        state.sections = initialState.sections;
      }
    },
    setSelectedNandu: (state, action: PayloadAction<SelectedNandu>) => {
      state.selectedNandu = {
        sectionId: action.payload.sectionId,
        nanduIndex: action.payload.nanduIndex,
      };
    },
    resetSelectedNandu: state => {
      state.selectedNandu = {
        sectionId: initialState.selectedNandu.sectionId,
        nanduIndex: initialState.selectedNandu.nanduIndex,
      };
    },
    addNanduSectionInput: (
      state,
      action: PayloadAction<{ sectionId: number }>
    ) => {
      state.sections[action.payload.sectionId].nandu.push({});
    },
    addMovementSectionInput: (
      state,
      action: PayloadAction<{ sectionId: number }>
    ) => {
      state.sections[action.payload.sectionId].movement.push({});
    },
    removeNanduFormSectionInput: (
      state,
      action: PayloadAction<{ sectionId: number; nanduIndex: number }>
    ) => {
      const section = state.sections[action.payload.sectionId];
      const formType = state.nanduFormType;

      if (formType === NANDU) {
        section.nandu.splice(action.payload.nanduIndex, 1);
      } else {
        section.movement.splice(action.payload.nanduIndex, 1);
      }
    },
    changeNanduFormNandu: (
      state,
      action: PayloadAction<NanduFormDataNandu | NanduConnection>
    ) => {
      const sectionId = state.selectedNandu.sectionId;
      const nanduIndex = state.selectedNandu.nanduIndex;

      if (
        (sectionId || sectionId !== null) &&
        (nanduIndex || nanduIndex !== null)
      ) {
        state.sections[sectionId].nandu.splice(nanduIndex, 1, action.payload);
      }
    },
    changeNanduFormMovement: (state, action: PayloadAction<NanduMovement>) => {
      const sectionId = state.selectedNandu.sectionId;
      const nanduIndex = state.selectedNandu.nanduIndex;

      if (
        (sectionId || sectionId !== null) &&
        (nanduIndex || nanduIndex !== null)
      ) {
        state.sections[sectionId].movement.splice(
          nanduIndex,
          1,
          action.payload
        );
      }
    },
    calculateNanduScores: (
      state,
      action: PayloadAction<{ removedConnection: SelectedNandu } | undefined>
    ) => {
      if (state.connections) {
        const calculatedScores = calculateNanduScore({
          sections: state.sections,
          connections: state.connections,
          removedPlusConnection: action?.payload?.removedConnection,
        });

        state.sections = calculatedScores.sections;
        state.nanduScores = calculatedScores.nanduScores;
      }
    },
    savePdf: (
      state,
      action: PayloadAction<{ filename: string; userInfo: UserInfo }>
    ) => {
      state.nanduPDF = {
        filename: action.payload.filename,
        pdf: generatePDF(state, action.payload.userInfo),
        userInfo: action.payload.userInfo,
      };
    },
    changeNanduFormType: (
      state,
      action: PayloadAction<{ nanduFormType: string }>
    ) => {
      state.nanduFormType = action.payload.nanduFormType;
    },
    verifyAllRequiredMovements: (
      state,
      action: PayloadAction<{ hasRequiredMovements: boolean }>
    ) => {
      const movementScores = {
        allRequiredMovements: action.payload.hasRequiredMovements,
        isValidMovementForm: action.payload.hasRequiredMovements,
      };

      state.movementScores = movementScores;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  addMovementSectionInput,
  addNanduSectionInput,
  calculateNanduScores,
  changeNanduFormMovement,
  changeNanduFormNandu,
  changeNanduFormType,
  removeNanduFormSectionInput,
  resetSelectedNandu,
  savePdf,
  setCompetitionEventId,
  setEventParticipationId,
  setNanduFormData,
  setSections,
  setSelectedNandu,
  verifyAllRequiredMovements,
} = slice.actions;

export const changeMovement = createAsyncThunk<void, NanduMovement, ThunkAPI>(
  "nanduForm/changeMovement",
  async (movement, { dispatch, getState }) => {
    let { sections } = getState().nanduForm;
    const movementFormHasDuplicates = hasDuplicates({ movement, sections });

    if (!movementFormHasDuplicates) {
      dispatch(changeNanduFormMovement(movement));
      sections = getState().nanduForm.sections;

      const { movements } = getState().nanduForm;

      if (movements) {
        dispatch(
          verifyAllRequiredMovements({
            hasRequiredMovements: hasAllRequiredMovements({
              movements,
              sections,
            }),
          })
        );
      }

      const { nanduScores, movementScores, eventParticipationId } =
        getState().nanduForm;

      await EventParticipationAPI.updateEventParticipationNanduForm({
        nanduForm: { sections, nanduScores, movementScores },
        id: eventParticipationId,
      });
    } else {
      // display error
    }
  }
);

export const changeNandu = createAsyncThunk<
  void,
  NanduFormDataNandu | NanduConnection,
  ThunkAPI
>("nanduForm/changeNandu", async (movement, { dispatch, getState }) => {
  dispatch(changeNanduFormNandu(movement));
  dispatch(calculateNanduScores());

  const { sections, nanduScores, movementScores, eventParticipationId } =
    getState().nanduForm;

  await EventParticipationAPI.updateEventParticipationNanduForm({
    nanduForm: { sections, nanduScores, movementScores },
    id: eventParticipationId,
  });
});

export const addSectionInput = createAsyncThunk<void, NanduSelection, ThunkAPI>(
  "nanduForm/addSectionInput",
  async (selectedNandu, { dispatch, getState }) => {
    const formType = getState().nanduForm.nanduFormType;
    const { sectionId } = selectedNandu;

    if (formType === NANDU) {
      dispatch(addNanduSectionInput({ sectionId }));
    } else {
      dispatch(addMovementSectionInput({ sectionId }));
    }

    dispatch(setSelectedNandu(selectedNandu));
  }
);

interface RemoveSectionInput extends NanduSelection {
  nandu: NanduFormDataNandu | NanduConnection;
}

export const removeSectionInput = createAsyncThunk<
  void,
  RemoveSectionInput,
  ThunkAPI
>(
  "nanduForm/removeSectionInput",
  async (selectedNandu, { dispatch, getState }) => {
    const formType = getState().nanduForm.nanduFormType;

    dispatch(removeNanduFormSectionInput(selectedNandu));

    const { nandu } = selectedNandu;

    if (formType === NANDU) {
      if ("code" in nandu && nandu.code === "+") {
        dispatch(calculateNanduScores({ removedConnection: selectedNandu }));
      } else {
        dispatch(calculateNanduScores());
      }
    } else {
      const { sections, movements } = getState().nanduForm;

      if (movements) {
        dispatch(
          verifyAllRequiredMovements({
            hasRequiredMovements: hasAllRequiredMovements({
              movements,
              sections,
            }),
          })
        );
      }
    }

    const { sections, nanduScores, movementScores, eventParticipationId } =
      getState().nanduForm;

    await EventParticipationAPI.updateEventParticipationNanduForm({
      nanduForm: { sections, nanduScores, movementScores },
      id: eventParticipationId,
    });
  }
);

export const dropSectionInput = createAsyncThunk<void, never, ThunkAPI>(
  "nanduForm/removeSectionInput",
  async (arg, { dispatch, getState }) => {
    const formType = getState().nanduForm.nanduFormType;

    if (formType === NANDU) {
      dispatch(calculateNanduScores());
    } else {
      const { sections, movements } = getState().nanduForm;

      if (movements) {
        dispatch(
          verifyAllRequiredMovements({
            hasRequiredMovements: hasAllRequiredMovements({
              movements,
              sections,
            }),
          })
        );
      }
    }
  }
);

type ChangeCompetitionEvent = {
  competitionEventId: number;
  eventParticipationId: number;
};

export const changeCompetitionEvent = createAsyncThunk<
  void,
  ChangeCompetitionEvent,
  ThunkAPI
>(
  "nanduForm/changeCompetitionEvent",
  async (
    { competitionEventId, eventParticipationId },
    { dispatch, getState }
  ) => {
    const nanduFormData =
      await NanduFormAPI.getNanduFormData(competitionEventId);

    dispatch(setNanduFormData(nanduFormData));
    dispatch(setEventParticipationId(eventParticipationId));

    const nanduForm =
      await EventParticipationAPI.getParticipantNanduForm(eventParticipationId);

    dispatch(setSections(nanduForm));
    dispatch(calculateNanduScores());

    const { sections, movements } = getState().nanduForm;

    if (movements) {
      dispatch(
        verifyAllRequiredMovements({
          hasRequiredMovements: hasAllRequiredMovements({
            movements,
            sections,
          }),
        })
      );
    }
  }
);

// Other code such as selectors can use the imported `RootState` type
export const getFormType = (state: RootState) => state.nanduForm.nanduFormType;
export const getSections = (state: RootState) => state.nanduForm.sections;
export const getNanduScores = (state: RootState) => state.nanduForm.nanduScores;
export const getIsValidNanduForm = (state: RootState) =>
  state.nanduForm.nanduScores.isValidNanduForm;
export const getIsValidMovementForm = (state: RootState) =>
  state.nanduForm.movementScores.isValidMovementForm;
export const getAllRequiredMovements = (state: RootState) =>
  state.nanduForm.movementScores.allRequiredMovements;
export const getNanduPDF = (state: RootState) => state.nanduForm.nanduPDF.pdf;
export const getNanduPDFFilename = (state: RootState) =>
  state.nanduForm.nanduPDF.filename;
export const getNanduPDFFirstName = (state: RootState) =>
  state.nanduForm.nanduPDF.userInfo.firstName;
export const getNanduPDFLastName = (state: RootState) =>
  state.nanduForm.nanduPDF.userInfo.lastName;
export const getNanduPDFAssociation = (state: RootState) =>
  state.nanduForm.nanduPDF.userInfo.association;
export const getNanduPDFGender = (state: RootState) =>
  state.nanduForm.nanduPDF.userInfo.gender;
export const getNanduPDFHeight = (state: RootState) =>
  state.nanduForm.nanduPDF.userInfo.height;
export const getNanduPDFEmail = (state: RootState) =>
  state.nanduForm.nanduPDF.userInfo.email;
export const getNanduPDFPhone = (state: RootState) =>
  state.nanduForm.nanduPDF.userInfo.phone;
export const getConnections = (state: RootState) => state.nanduForm.connections;
export const getMovements = (state: RootState) => state.nanduForm.movements;
export const getNandu = (state: RootState) => state.nanduForm.nandu;
export const getSelectedNandu = (state: RootState) =>
  state.nanduForm.selectedNandu;
export const getEventParticipationId = (state: RootState) =>
  state.nanduForm.eventParticipationId;
export const getCompetitionEventId = (state: RootState) =>
  state.nanduForm.competitionEventId;
export const getIsPastDeadline = (state: RootState) =>
  state.nanduForm.isPastDeadline;
export const getNanduFormDeadline = (state: RootState) =>
  state.nanduForm.nanduFormDeadline;

export default slice.reducer;
