import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "./reducers";

export interface AdminDashboardState {
  showSidebar: boolean;
  sidebarUnfoldable: boolean;
  theme: string;
}

const initialState = {
  showSidebar: true,
  sidebarUnfoldable: false,
  theme: "light",
} as AdminDashboardState;

export const slice = createSlice({
  name: "adminDashboard",
  initialState,
  reducers: {
    changeTheme: (state, action: PayloadAction<string>) => {
      state.theme = action.payload;
    },
    changeShowSidebar(state, action: PayloadAction<boolean>) {
      state.showSidebar = action.payload;
    },
    toggleSideBar: (state) => {
      state.showSidebar = !state.showSidebar;
    },
    toggleSidebarUnfoldable: (state) => {
      state.sidebarUnfoldable = !state.sidebarUnfoldable;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  changeShowSidebar,
  changeTheme,
  toggleSidebarUnfoldable,
  toggleSideBar,
} = slice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getTheme = (state: RootState) => state.adminDashboard.theme;
export const getShowSidebar = (state: RootState) =>
  state.adminDashboard.showSidebar;
export const getSidebarUnfoldable = (state: RootState) =>
  state.adminDashboard.sidebarUnfoldable;

export default slice.reducer;
