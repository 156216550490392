import { SET_COMPETITION_ID } from "./actionTypes";

// const state = {
//   "competitionSelect-1": {
//      label: 'competition name',
//      value: id,
//    }
// };

function CompetitionSelectReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case SET_COMPETITION_ID: {
      const { competitionSelectId, competition } = action;

      return {
        ...state,
        [competitionSelectId]: competition,
      };
    }
    default:
      return state;
  }
}

export { CompetitionSelectReducer };
