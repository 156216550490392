import { GET, POST, PUT, DELETE, POSTFILE, PUTFILE } from "api/config";
import formDataFromObject from "helpers/formDataFromObject";

const ParticipantsAPI = {
  async getAll() {
    const { participants } = await GET(`participants/all`);

    return participants;
  },
  async getAllForUser() {
    const { participants } = await GET(`participants`);

    return participants;
  },
  async getList() {
    const { participants } = await GET(`participants/list`);

    return participants;
  },
  async getAllByName(name) {
    if (name) {
      const { participants } = await GET(`participants/name/${name}`);

      return participants;
    }
  },
  async getById(id) {
    const { participant } = await GET(`participants/${id}`);

    return participant;
  },
  async add({ participantInput, userId }) {
    const formData = formDataFromObject(participantInput);
    let route = "participants";

    if (userId) {
      route += `/admin/${userId}`;
    }

    return await POSTFILE(route, formData);
  },
  remove({ id }) {
    return DELETE(`participants/${id}`);
  },
  async recover(id) {
    return await POST(`participants/${id}/recover`);
  },
  async update({ participantInput }) {
    const { id } = participantInput;
    const formData = formDataFromObject(participantInput);

    return await PUTFILE(`participants/${id}`, formData);
  },
  async updateTeam({ id, teamId }) {
    return await PUT(`participants/${id}/team`, { teamId });
  },
  async removeParticipantsFromTeam({ ids }) {
    return await DELETE(`participants/team`, { ids });
  },
};

export { ParticipantsAPI };
