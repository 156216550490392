import { ADD_TOAST, REMOVE_TOAST } from "./actionTypes";

function ToastReducer(state = [], action) {
  const { type } = action;

  switch (type) {
    case ADD_TOAST:
      const { toast } = action;

      return [...state, toast];
    case REMOVE_TOAST:
      const { id } = action;

      return [...state.slice(0, id), ...state.slice(id + 1, state.length)];
    default:
      return state;
  }
}

export { ToastReducer };
