import { SET_RING_ID } from "./actionTypes";

const RingSelectActions = {
  setRing(selectedDay, ring, ringSelectElementId) {
    return {
      type: SET_RING_ID,
      selectedDay,
      ring,
      ringSelectElementId,
    };
  },
};

export { RingSelectActions };
