import { createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";

import type { RootState } from "./reducers";

export interface I18nState {
  language: string;
}

const initialState = {
  language: "en",
} as I18nState;

export const slice = createSlice({
  name: "i18n",
  initialState,
  reducers: {
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
    },
  },
});

// Action creators are generated for each case reducer function
export const { setLanguage } = slice.actions;

// Other code such as selectors can use the imported `RootState` type
export const getLanguage = (state: RootState) => state.i18n.language;

export default slice.reducer;
