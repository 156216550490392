import dispatchToast from 'helpers/dispatchToast';

export default function handleError(error, shouldLog=true) {
  let message = error;

  if (shouldLog) {
    console.error(error);
  }

  if (typeof message !== 'string') {
    if (error.message && typeof error.message === 'string') {
      message = error.message;
    } else {
      message = 'Unknown error occured, please contact your administrator.';
    }
  }

  dispatchToast({ message });
}
