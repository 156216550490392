import { useRoutes } from "react-router-dom";

import { AuthSelectors } from "redux/auth";
import { useAppSelector } from "redux/hooks";
import getRoutes from "../../routes";

function Routes() {
  const isAdmin = useAppSelector(AuthSelectors.isAdmin);
  const isJudge = useAppSelector(AuthSelectors.isJudge);
  const routes = getRoutes({ isAdmin, isJudge });
  const routesElement = useRoutes(routes);

  return routesElement;
}

export default Routes;
