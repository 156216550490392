import { SET_COMPETITION_DAY } from "./actionTypes";
import { CompetitionDaysSelect, CompetitionDaysSelectAction } from "./types";

function CompetitionDaysSelectReducer(
  state: CompetitionDaysSelect = {},
  action: CompetitionDaysSelectAction
) {
  const { type } = action;

  switch (type) {
    case SET_COMPETITION_DAY: {
      const { competitionDaysSelectId, competitionDay } = action;

      return {
        ...state,
        [competitionDaysSelectId]: competitionDay,
      };
    }
    default:
      return state;
  }
}

export { CompetitionDaysSelectReducer };
