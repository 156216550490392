import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import type { RouteObject } from "react-router-dom";

// const Tickets = React.lazy(() => import("./scenes/Home/scenes/Tickets"));
// const About = React.lazy(() => import("./scenes/About"));
const Account = React.lazy(() => import("./scenes/Home/scenes/Account"));
const Admin = React.lazy(() => import("./scenes/Admin"));
const AdminHome = React.lazy(() => import("./scenes/Admin/scenes/AdminHome"));
const AdminLogin = React.lazy(() => import("./components/admin/AdminLogin"));
const AgeGroups = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/AgeGroups"
    )
);
const AllResultsHome = React.lazy(() => import("./scenes/AllResultsHome"));
const AthleteManager = React.lazy(
  () => import("scenes/Admin/scenes/AthleteManager")
);
const BatchEvents = React.lazy(
  () =>
    import(
      "./scenes/Admin/scenes/TournamentManager/CompetitionManager/BatchEvents"
    )
);
const ChampionshipSetup = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/ChampionshipSetup"
    )
);
const CheckIn = React.lazy(() => import("./scenes/CheckIn"));
const CheckInHome = React.lazy(
  () => import("scenes/CheckIn/scenes/CheckInHome/CheckInHome")
);
const CheckInLogin = React.lazy(
  () => import("scenes/CheckIn/components/CheckInLogin/CheckInLogin")
);
const Checkout = React.lazy(() => import("./scenes/Home/scenes/Checkout"));
const CheckoutConfirmation = React.lazy(
  () => import("./scenes/Home/scenes/Checkout/CheckoutConfirmation")
);
const Competition = React.lazy(
  () =>
    import(
      "./scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition"
    )
);
const CompetitionEdit = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/CompetitionEdit"
    )
);
const CompetitionEvents = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/CompetitionEvents"
    )
);
const CompetitionManager = React.lazy(
  () =>
    import(
      "./scenes/Admin/scenes/TournamentManager/CompetitionManager/CompetitionManager"
    )
);
const DrawLots = React.lazy(() => import("./scenes/Home/scenes/DrawLots"));
// const EditNanduForm = React.lazy(
//   () => import("./scenes/NanduForm/components/EditNanduForm")
// );
const EditParticipant = React.lazy(
  () => import("components/EditParticipant/EditParticipant")
);
const ErrorMessageManager = React.lazy(
  () => import("./scenes/Admin/scenes/ErrorMessageManager")
);
const EventCategoryManager = React.lazy(
  () => import("./scenes/Admin/scenes/EventCategoryManager")
);
const EventCategoryRestrictions = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/EventCategoryRestrictions"
    )
);
const EventManager = React.lazy(
  () => import("./scenes/Admin/scenes/EventManager")
);
const EventsPane = React.lazy(() => import("components/EventsPane"));
const FeeManager = React.lazy(() => import("./scenes/Admin/scenes/FeeManager"));
const ForgotPassword = React.lazy(() => import("./scenes/ForgotPassword"));
const Home = React.lazy(() => import("./scenes/Home"));
const HospitalityManager = React.lazy(
  () =>
    import(
      "./scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/HospitalityManager"
    )
);
const HospitalityRegistration = React.lazy(
  () => import("scenes/Home/scenes/HospitalityRegistration")
);
const Judging = React.lazy(() => import("./scenes/Judging"));
const JudgingHome = React.lazy(
  () => import("scenes/Judging/scenes/JudgingHome")
);
const JudgingLogin = React.lazy(
  () => import("scenes/Judging/components/JudgingLogin")
);
const Login = React.lazy(() => import("./scenes/Login"));
const MasterSheet = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/MasterSheet"
    )
);
const MovementsManager = React.lazy(
  () => import("./scenes/Admin/scenes/MovementsManager")
);
const MyResults = React.lazy(() => import("scenes/Home/scenes/MyResults"));
const NanduForm = React.lazy(() => import("components/NanduForm/NanduForm"));
const NanduManager = React.lazy(
  () => import("./scenes/Admin/scenes/NanduManager")
);
const NanduRulesetsManager = React.lazy(
  () => import("scenes/Admin/scenes/NanduRulesetsManager/NanduRulesetsManager")
);
const NanduScoresManager = React.lazy(
  () => import("./scenes/Admin/scenes/NanduScoresManager")
);
const NotFound = React.lazy(() => import("./scenes/NotFound"));
const Participant = React.lazy(
  () => import("./scenes/Home/scenes/Participants/components/Participant")
);
const ParticipantManager = React.lazy(
  () => import("scenes/Admin/scenes/ParticipantManager")
);
const Participants = React.lazy(
  () => import("./scenes/Home/scenes/Participants/Participants")
);
const ParticipantSchedule = React.lazy(
  () => import("./scenes/Schedule/components/ParticipantSchedule")
);
const ParticipantVideos = React.lazy(
  () => import("components/ParticipantVideos/ParticipantVideos")
);
const ParticipantView = React.lazy(
  () => import("./scenes/Admin/scenes/ParticipantView")
);
const PrintingStation = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/PrintingStation/PrintingStation"
    )
);
// const PrintNanduForm = React.lazy(
//   () => import("./scenes/NanduForm/components/PrintNanduForm")
// );
const Register = React.lazy(() => import("./scenes/Register"));
const Reports = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/Reports/Reports"
    )
);
const ResetPassword = React.lazy(() => import("./scenes/ResetPassword"));
const RestrictedUserManager = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/RestrictedUserManager"
    )
);
const Ring = React.lazy(
  () =>
    import(
      "./scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/Rings/components/Ring"
    )
);
const Rings = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/Competition/components/Rings"
    )
);
const RulesetNanduManager = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/NanduRulesetsManager/RulesetNanduManager/RulesetNanduManager"
    )
);
const Schedule = React.lazy(() => import("./scenes/Schedule"));
const ScheduleHome = React.lazy(
  () => import("./scenes/Schedule/components/ScheduleHome")
);
const Team = React.lazy(() => import("scenes/Admin/scenes/TeamManager/Team"));
const TeamManager = React.lazy(
  () => import("./scenes/Admin/scenes/TeamManager")
);
const TicketManager = React.lazy(
  () =>
    import(
      "scenes/Admin/scenes/TournamentManager/CompetitionManager/TicketManager"
    )
);
const Tournament = React.lazy(
  () => import("scenes/Admin/scenes/TournamentManager/Tournament/Tournament")
);
const TournamentManager = React.lazy(
  () => import("./scenes/Admin/scenes/TournamentManager/TournamentManager")
);
const TournamentReports = React.lazy(
  () => import("scenes/Admin/scenes/TournamentManager/TournamentReports")
);
const User = React.lazy(() => import("./scenes/Admin/scenes/UserManager/User"));
const UserManager = React.lazy(
  () => import("./scenes/Admin/scenes/UserManager")
);
const VideoPlayer = React.lazy(() => import("./scenes/VideoPlayer"));
const WebRouter = React.lazy(
  () => import("scenes/ClientDashboard/ClientDashboard")
);
const WebsiteMessageManager = React.lazy(
  () => import("./scenes/Admin/scenes/WebsiteMessageManager")
);
const WushuStyleManager = React.lazy(
  () => import("./scenes/Admin/scenes/WushuStyleManager")
);

function getParticipantRoutes(isAdmin = false) {
  const participantRoutes = [
    {
      index: true,
      element: <EventsPane isAdmin={isAdmin} />,
    },
    {
      path: "events",
      element: <EventsPane isAdmin={isAdmin} />,
    },
    {
      path: "info",
      element: (
        <EditParticipant
          navigateToAfterDelete={isAdmin ? -1 : "/participants"}
        />
      ),
    },
    {
      path: "video-submissions",
      element: <ParticipantVideos />,
    },
    {
      path: "schedule",
      element: <ParticipantSchedule />,
    },
    { path: "nandu-forms", element: <NanduForm /> },
    { path: "results", element: <MyResults /> },
  ];

  return participantRoutes;
}

function getRoutes({ isAdmin = false, isJudge = false }) {
  const routes: RouteObject[] = [
    {
      path: "/",
      element: <WebRouter />,
      children: [
        {
          path: "*",
          element: <Home />,
          children: [
            { index: true, element: <Navigate to="participants" /> },
            { path: "account", element: <Account /> },
            { path: "lots", element: <DrawLots /> },
            {
              path: "participants",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { index: true, element: <Participants /> },
                {
                  path: ":participantId",
                  element: <Participant />,
                  children: getParticipantRoutes(),
                },
              ],
            },
            { path: "hospitality", element: <HospitalityRegistration /> },
            // {
            //   path: "nanduform",
            //   element: <NanduForm />,
            //   children: [
            //     { path: "*", element: <EditNanduForm /> },
            //     { path: "print", element: <PrintNanduForm /> },
            //   ],
            // },
            // { path: "tickets", element: <Tickets /> },
            {
              path: "checkout",
              children: [
                { index: true, element: <Checkout /> },
                { path: "confirmation", element: <CheckoutConfirmation /> },
              ],
            },
            { path: "*", element: <NotFound /> },
          ],
        },
        // { path: "about", element: <About /> },
        { path: "all-results", element: <AllResultsHome /> },
        { path: "forgotPassword", element: <ForgotPassword /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "resetPassword/:token", element: <ResetPassword /> },
        {
          path: "schedule",
          element: <Schedule />,
          children: [
            { index: true, element: <ScheduleHome isAdmin={isAdmin} /> },
            { path: ":participantId/events", element: <ParticipantSchedule /> },
          ],
        },
        { path: "video/:eventParticipationId", element: <VideoPlayer /> },
      ],
    },
    {
      path: "adm",
      element: <Admin />,
      children: [
        { index: true, element: <AdminLogin /> },
        {
          path: "home",
          element: <AdminHome />,
          children: [
            { index: true, element: <Navigate to="tournaments" /> },
            {
              path: "tournaments",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                {
                  index: true,
                  element: <TournamentManager />,
                },
                {
                  path: ":tournamentId",
                  element: <Tournament />,
                  children: [
                    {
                      index: true,
                      element: <CompetitionManager />,
                    },
                    {
                      path: "rings",
                      element: (
                        <>
                          <Outlet />
                        </>
                      ),
                      children: [
                        {
                          index: true,
                          element: <Rings />,
                        },
                        {
                          path: ":ringId",
                          element: <Ring />,
                        },
                      ],
                    },
                    { path: "tickets", element: <TicketManager /> },
                    { path: "printingStation", element: "TODO" },
                    { path: "hospitality", element: "TODO" },
                    { path: "athletes", element: "TODO" },
                    { path: "reports", element: <TournamentReports /> },
                  ],
                },
                {
                  path: ":tournamentId/competitions/:competitionId",
                  element: <Competition />,
                  children: [
                    {
                      index: true,
                      element: <CompetitionEdit />,
                    },
                    {
                      path: "championshipSetup",
                      element: <ChampionshipSetup />,
                    },
                    {
                      path: "ageGroups",
                      element: <AgeGroups />,
                    },
                    {
                      path: "eventRegistrationRestrictions",
                      element: <EventCategoryRestrictions />,
                    },
                    {
                      path: "masterSheet",
                      element: (
                        <>
                          <Outlet />
                        </>
                      ),
                      children: [
                        {
                          index: true,
                          element: <MasterSheet />,
                        },
                        {
                          path: "batch-events",
                          element: <BatchEvents />,
                        },
                      ],
                    },
                    {
                      path: "participants",
                      element: (
                        <>
                          <Outlet />
                        </>
                      ),
                      children: [
                        { index: true, element: <AthleteManager /> },
                        {
                          path: ":participantId",
                          element: <ParticipantView />,
                          children: getParticipantRoutes(true),
                        },
                      ],
                    },
                    {
                      path: "competitionEvents",
                      element: <CompetitionEvents />,
                    },
                    {
                      path: "printingStation",
                      element: <PrintingStation />,
                    },
                    {
                      path: "reports",
                      element: <Reports />,
                    },
                    {
                      path: "awards",
                      element: <AllResultsHome />,
                    },
                    {
                      path: "restricted-users",
                      element: <RestrictedUserManager />,
                    },
                    {
                      path: "hospitality",
                      element: <HospitalityManager />,
                    },
                  ],
                },
              ],
            },
            {
              path: "users",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { index: true, element: <UserManager /> },
                {
                  path: ":userId",
                  element: (
                    <>
                      <Outlet />
                    </>
                  ),
                  children: [
                    { index: true, element: <User /> },
                    {
                      path: "participants",
                      element: (
                        <>
                          <h3>Participant View</h3>
                          <hr />
                          <Outlet />
                        </>
                      ),
                      children: [
                        {
                          path: ":participantId",
                          element: <ParticipantView />,
                          children: getParticipantRoutes(true),
                        },
                      ],
                    },
                  ],
                },
              ],
            },
            {
              path: "participants",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { index: true, element: <ParticipantManager /> },
                {
                  path: ":participantId",
                  element: (
                    <>
                      <h3>Participant View</h3>
                      <hr />
                      <ParticipantView />
                    </>
                  ),
                  children: getParticipantRoutes(true),
                },
              ],
            },
            { path: "wushu-styles", element: <WushuStyleManager /> },
            { path: "website-messages", element: <WebsiteMessageManager /> },
            { path: "fees", element: <FeeManager /> },
            { path: "error-messages", element: <ErrorMessageManager /> },
            { path: "event-categories", element: <EventCategoryManager /> },
            { path: "events", element: <EventManager /> },
            {
              path: "teams",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { index: true, element: <TeamManager /> },
                { path: ":id", element: <Team /> },
              ],
            },
            // { path: "masterSheet", element: <MasterSheet /> },
            { path: "nandu", element: <NanduManager /> },
            { path: "nanduScores", element: <NanduScoresManager /> },
            {
              path: "nanduRulesets",
              element: (
                <>
                  <Outlet />
                </>
              ),
              children: [
                { index: true, element: <NanduRulesetsManager /> },
                { path: ":nanduRulesetId", element: <RulesetNanduManager /> },
              ],
            },
            { path: "movements", element: <MovementsManager /> },
            { path: "*", element: <NotFound /> },
          ],
        },
        { path: "*", element: <NotFound /> },
      ],
    },
    {
      path: "judging",
      element: <Judging />,
      children: [
        { index: true, element: <JudgingLogin /> },
        {
          path: "home",
          element: isJudge || isAdmin ? <JudgingHome /> : <Navigate to=".." />,
        },
        { path: "*", element: <NotFound /> },
      ],
    },
    {
      path: "roll-call",
      element: <CheckIn />,
      children: [
        { index: true, element: <CheckInLogin /> },
        {
          path: "home",
          element:
            isJudge || isAdmin ? (
              <CheckInHome isAdmin={isAdmin} />
            ) : (
              <Navigate to=".." />
            ),
        },
        { path: "*", element: <NotFound /> },
      ],
    },
  ];

  return routes;
}

export default getRoutes;
