import { SET_COMPETITION_DAY } from "./actionTypes";
import { CompetitionDay } from "./types";

const CompetitionDaysSelectActions = {
  setCompetitionDay(
    competitionDay: CompetitionDay,
    competitionDaysSelectId: string
  ) {
    return {
      type: SET_COMPETITION_DAY,
      competitionDay,
      competitionDaysSelectId,
    };
  },
};

export { CompetitionDaysSelectActions };
