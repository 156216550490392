import { ADD_TOAST, REMOVE_TOAST } from "./actionTypes";

const ToastActions = {
  addToast(toast) {
    return { type: ADD_TOAST, toast };
  },
  removeToast(id) {
    return { type: REMOVE_TOAST, id };
  }
};

export { ToastActions } ;
