import {
  COLLAPSE_ALL,
  COLLAPSE_COLLAPSE,
  EXPAND_ALL,
  EXPAND_COLLAPSE,
  HIDE_COLLAPSE,
  SHOW_ALL,
} from "./actionTypes";

const CollapseGroupActions = {
  collapseAll(collapseGroupId) {
    return { type: COLLAPSE_ALL, collapseGroupId };
  },
  collapseCollapse(collapseGroupId, collapseId) {
    return { type: COLLAPSE_COLLAPSE, collapseGroupId, collapseId };
  },
  expandAll(collapseGroupId) {
    return { type: EXPAND_ALL, collapseGroupId };
  },
  expandCollapse(collapseGroupId, collapseId) {
    return { type: EXPAND_COLLAPSE, collapseGroupId, collapseId };
  },
  hideCollapse(collapseGroupId, collapseId) {
    return { type: HIDE_COLLAPSE, collapseGroupId, collapseId };
  },
  showAll(collapseGroupId) {
    return { type: SHOW_ALL, collapseGroupId };
  },
};

export { CollapseGroupActions };
