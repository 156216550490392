import { GET, POST } from "api/config";
import qs from "qs";

const ChampionshipAPI = {
  async getAllForCompetition(id) {
    const { championships } = await GET(`championships/competition/${id}`);

    return championships;
  },
  async getById(id) {
    const { championship } = await GET(`championships/${id}`);

    return championship;
  },
  async getEligibleChampionshipsForParticipant({
    participantId,
    competitionId,
  }) {
    const queryParams = qs.stringify({ participantId, competitionId });
    const { championships } = await GET(
      `/championships/eligibleChampionships?${queryParams}`
    );

    return championships;
  },
  async getTeamTrialResults(id) {
    const { tiers } = await GET(`/championships/${id}/team-trial-results`);

    return tiers;
  },
  async getAllAroundResults(id) {
    const { championshipResults } = await GET(
      `championships/${id}/all-around-results`
    );

    return championshipResults;
  },
  async register(championshipRegistrationInput) {
    return await POST(`/championships/register`, {
      championshipRegistrationInput,
    });
  },
  async deregister(championshipRegistrationInput) {
    return await POST(`/championships/deregister`, {
      championshipRegistrationInput,
    });
  },
  async lockInChampionshipResults(championshipInput) {
    const { championshipId } = championshipInput;

    return await POST(`/championships/${championshipId}/lockIn`, {
      championshipInput,
    });
  },
};

export { ChampionshipAPI };
