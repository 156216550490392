import { LOGIN, LOGOUT, UPDATE_EMAIL } from './actionTypes';

const initialState = {
  user: null,
  isAuthenticated: false,
  judgeId: null,
  authToken: null,
};

function AuthReducer(state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      const { user } = action;
      const { authToken } = user;
      return {
        user,
        isAuthenticated: true,
        authToken,
      };
    case LOGOUT:
      return initialState;
    case UPDATE_EMAIL:
      state.user.email = action.email;

      return state;
    default:
      return state;
  }
}

export { AuthReducer };
