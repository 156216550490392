import { LOGIN, LOGOUT, UPDATE_EMAIL } from "./actionTypes";
import { AuthAPI } from "api";

const AuthActions = {
  login({ emailOrUsername, isUsername, password }) {
    return async (dispatch) => {
      const user = await AuthAPI.login({
        emailOrUsername,
        isUsername,
        password,
      });
      if (user) {
        dispatch({ type: LOGIN, user });
      }
    };
  },
  logout() {
    return (dispatch) => {
      dispatch({ type: LOGOUT });
      localStorage.clear();
    };
  },
  register(newUser) {
    return async (dispatch) => {
      const user = await AuthAPI.register(newUser);
      if (user) {
        dispatch({ type: LOGIN, user });
      }
    };
  },
  updateEmail(email) {
    return (dispatch) => {
      dispatch({ type: UPDATE_EMAIL, email });
    };
  },
};

export { AuthActions };
