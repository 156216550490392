import { configureStore } from "@reduxjs/toolkit";
import throttle from "lodash.throttle";

import { loadReduxState, saveState } from "../helpers/localStorage";
import rootReducer from "./reducers";

const store = configureStore({
  preloadedState: loadReduxState(),
  reducer: rootReducer,
});

store.subscribe(
  throttle(() => {
    saveState(store.getState());
  }, 1000)
);

// Infer the `AppDispatch` type from the store itself
export type AppDispatch = typeof store.dispatch;
export default store;
