import React from "react";
import store from "redux/store";
import { ToastActions } from "redux/toast";

type DispatchToast = {
  message: string | React.ReactElement;
  type?: string;
  autohide?: boolean;
  delay?: number;
};

export default function dispatchToast({
  message,
  type,
  autohide,
  delay,
}: DispatchToast) {
  store.dispatch(ToastActions.addToast({ message, type, autohide, delay }));
}
