import qs from "qs";

import { GET } from "api/config";

const TournamentAPI = {
  async getCurrentTournament({ expanded, getInactive }) {
    const queryParams = qs.stringify({
      expanded,
      getInactive,
    });
    const url = `tournaments/current?${queryParams}`;
    const { tournament } = await GET(url);

    return tournament;
  },
};

export { TournamentAPI };
