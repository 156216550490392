import {
  COLLAPSE_ALL,
  COLLAPSE_COLLAPSE,
  EXPAND_ALL,
  EXPAND_COLLAPSE,
  HIDE_COLLAPSE,
  SHOW_ALL,
} from "./actionTypes";

// const state = {
//   "checkIn-1": {
//     EXPAND_ALL: 0,
//     collapse1: {
//       e: 1, (expanded)
//       h: 1, (hidden)
//     },
//   },
// };

function CollapseGroupReducer(state = {}, action) {
  const { type } = action;

  switch (type) {
    case COLLAPSE_COLLAPSE: {
      const { collapseGroupId, collapseId } = action;

      return {
        ...state,
        [collapseGroupId]: {
          ...state[collapseGroupId],
          [collapseId]: {
            ...state[collapseGroupId]?.[collapseId],
            e: 0,
          },
        },
      };
    }
    case EXPAND_COLLAPSE: {
      const { collapseGroupId, collapseId } = action;

      return {
        ...state,
        [collapseGroupId]: {
          ...state[collapseGroupId],
          [collapseId]: {
            ...state[collapseGroupId]?.[collapseId],
            e: 1,
          },
        },
      };
    }
    case HIDE_COLLAPSE: {
      const { collapseGroupId, collapseId } = action;

      return {
        ...state,
        [collapseGroupId]: {
          ...state[collapseGroupId],
          [collapseId]: {
            ...state[collapseGroupId][collapseId],
            h: 1,
          },
        },
      };
    }
    case SHOW_ALL: {
      const { collapseGroupId } = action;

      return {
        ...state,
        [collapseGroupId]: Object.entries(state[collapseGroupId]).reduce(
          (acc, [collapseId, collapseState]) => {
            if (collapseId !== EXPAND_ALL) {
              acc[collapseId] = {
                ...collapseState,
                h: 0,
              };
            }

            return acc;
          },
          {
            EXPAND_ALL: state[collapseGroupId].EXPAND_ALL,
          }
        ),
      };
    }
    case COLLAPSE_ALL: {
      const { collapseGroupId } = action;
      const newState = state?.[collapseGroupId]
        ? Object.entries(state[collapseGroupId]).reduce(
            (acc, [collapseId, collapseState]) => {
              if (collapseId !== EXPAND_ALL) {
                // eslint-disable-next-line no-unused-vars
                const { e, ...newState } = collapseState;

                acc[collapseId] = newState;
              }

              return acc;
            },
            {
              EXPAND_ALL: 0,
            }
          )
        : {
            EXPAND_ALL: 1,
          };

      return {
        ...state,
        [collapseGroupId]: newState,
      };
    }
    case EXPAND_ALL: {
      const { collapseGroupId } = action;
      const newState = state?.[collapseGroupId]
        ? Object.entries(state[collapseGroupId]).reduce(
            (acc, [collapseId, collapseState]) => {
              if (collapseId !== EXPAND_ALL) {
                // eslint-disable-next-line no-unused-vars
                const { e, ...newState } = collapseState;

                acc[collapseId] = newState;
              }

              return acc;
            },
            {
              EXPAND_ALL: 1,
            }
          )
        : {
            EXPAND_ALL: 1,
          };

      return {
        ...state,
        [collapseGroupId]: newState,
      };
    }
    default:
      return state;
  }
}

export { CollapseGroupReducer };
