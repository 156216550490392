import { CSpinner } from "@coreui/react";
import React, { Suspense } from "react";
import { QueryCache, ReactQueryCacheProvider } from "react-query";
import { BrowserRouter } from "react-router-dom";

import Routes from "./Routes";
import ToastContainer from "./ToastContainer";

const queryCache = new QueryCache({
  defaultConfig: {
    queries: {
      retry: 1,
    },
  },
});

function App() {
  return (
    <ReactQueryCacheProvider queryCache={queryCache}>
      <ToastContainer />
      <BrowserRouter>
        <Suspense fallback={<CSpinner color="primary" />}>
          <Routes />
        </Suspense>
      </BrowserRouter>
    </ReactQueryCacheProvider>
  );
}

export default App;
