import { POST } from "./config";

const AuthAPI = {
  async register(userInput) {
    const { user } = await POST("auth/register", { userInput });

    return user;
  },

  async login({ emailOrUsername, password, isUsername }) {
    const { user } = await POST("auth/login", {
      emailOrUsername,
      isUsername,
      password,
    });

    return user;
  },
};

export { AuthAPI };
